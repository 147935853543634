import { sortByProperty } from '@/helpers/sort';

export const gridDefaultColDef = {
  resizable: true,
  sortable: true,
};

export function gridColumns(ProfileLink) {
  return [
    {
      headerName: 'Full Name',
      flex: 1,
      sort: 'asc',
      sortable: false,
      valueGetter: (params) => params.data,
      cellRenderer: ProfileLink,
      comparator: (valueA, valueB) => sortByProperty(valueA, valueB, 'surname'),
    },
    {
      headerName: 'Country/Region',
      field: 'country',
      sortable: false,
      flex: 1,
    },
    {
      headerName: 'Category',
      flex: 1,
      sortable: false,
      autoHeight: true,
      cellRenderer: (params) => {
        const { positions } = params.data;

        return positions
          .filter((p) => p.parent?.entity_type !== 'coordinatingCommittee')
          .map((p) => {
            // Chair
            if (['Chair', 'Vice-Chair', 'Ordinary member'].includes(p.name)) {
              return `<div>${p.name}</div>`;
            }

            return `<div>${p.parent.name}</div>`;
          })
          .join('');
      },
    },
    {
      headerName: 'Role',
      flex: 1,
      sortable: false,
      autoHeight: true,
      cellRenderer: (params) => {
        const { positions } = params.data;

        return positions
          .map((p) => {
            // Skip role
            if (['Chair', 'Vice-Chair', 'Ordinary member'].includes(p.name)) {
              return '<div>&nbsp;</div>';
            }

            if (p.parent.name === 'Ex-officio member' && p.name !== 'Coordinating Committee Vice-Chair') {
              return '<div>&nbsp;</div>';
            }

            if (p.name === 'Coordinating Committee Vice-Chair') {
              const label = p.name;

              const CCs = params.data.originalPositions.filter((op) => op.coordinatingCommittee !== undefined);

              if (CCs.length > 0) {
                const CCNames = CCs.map((cc) => cc.parent.name).join(', ');
                return `<div>${label} (${CCNames})</div>`;
              }

              return `<div>${label}</div>`;
            }

            return `<div>${p.name}</div>`;
          })
          .join('');
      },
    },
  ];
}

export function handleAffiliates(affiliates) {
  const collection = [];

  // Duplicate affiliates for each position
  affiliates.forEach((a) => {
    a.positions.forEach((p) => {
      if (p.coordinatingCommittee) {
        return;
      }

      const clonedA = JSON.parse(JSON.stringify(a));
      clonedA.positions = [p];
      clonedA.originalPositions = a.positions;
      collection.push(clonedA);
    });
  });

  return collection;
}
